




























































































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import memberService from "@/service/memberService";
import { NotificationState } from "@/store/modules/notification";
import MemberDeleteDialog from "@/components/organisms/member/MemberDeleteDialog.vue";
import MemberEditDialog from "@/components/organisms/member/MemberEditDialog.vue";
import MemberDeliveryCouponDialog from "@/components/organisms/member/MemberDeliveryCouponDialog.vue";
import { Member, MemberFilter } from "@/graphql/client";
import MixinSearchTimer from "@/components/mixins/MixinSearchTimer.vue";
import { BooleanValueNode } from "graphql";

@Component({
  components: {
    MemberDeleteDialog,
    MemberEditDialog,
    MemberDeliveryCouponDialog,
  },
})
export default class MemberList extends Mixins(
  MixinFormatter,
  MixinSearchTimer
  ) {
  @Ref() readonly editDialog!: MemberEditDialog;
  @Ref() readonly deleteDialog!: MemberDeleteDialog;
  @Ref() readonly deliveryCouponDialog!: MemberDeliveryCouponDialog;
  //---------------------------
  // data
  //---------------------------
  items: Member[] = [];
  headers = [
    {
      text: "ID",
      value: "id",
      width: "15%",
    },
    {
      text: "氏名",
      value: "name",
    },
    {
      text: "会員登録日",
      value: "createdAt",
      width: "15%",
      formatter: this.formatDate,
    },
    {
      text: "",
      value: "couponAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "asMemberAction",
      width: "30px",
      sortable: false,
    },
  ];

  searchKeyword = "";
  searchMemberId = "";
  searchName = "";
  searchNameKana = "";
  searchPhone = "";
  searchEmail = "";
  loadingDataGrid = false;

  //pagenation
  totalMembers = 0;
  memberDataOptions:{
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
    groupBy: string[];
    groupDesc: boolean[];
  } = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
  };

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.searchMembers();
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  @Watch('memberDataOptions')
  public onChangeMemberDataOptions() {
    this.searchTimeOut(this.searchMembers);
  }

  @Watch('searchMemberId')
  public onChangeSearchMemberId() {
    this.searchTimeOut(this.searchMembers);
  }
  @Watch('searchName')
  public onChangeSearchName() {
    this.searchTimeOut(this.searchMembers);
  }
  @Watch('searchNameKana')
  public onChangeSearchNameKana() {
    this.searchTimeOut(this.searchMembers);
  }
  @Watch('searchPhone')
  public onChangeSearchPhone() {
    this.searchTimeOut(this.searchMembers);
  }
  @Watch('searchEmail')
  public onChangeSearchEmail() {
    this.searchTimeOut(this.searchMembers);
  }

  public async showMemberMypage(member: Member) {
    await memberService.showMypageAsMember(member);
  }


  /**
   * 会員を検索
   */
  public searchMembers(): void
  {
    const { sortBy, sortDesc, page, itemsPerPage } = this.memberDataOptions;


    let filter: MemberFilter = {};

    if (this.searchMemberId) {
      filter.memberId = this.searchMemberId;
    }

    if (this.searchName) {
      filter.name = this.searchName;
    }
    if (this.searchNameKana) {
      filter.nameKana = this.searchNameKana;
    }
    if (this.searchPhone) {
      filter.phone = this.searchPhone;
    }
    if (this.searchEmail) {
      filter.email = this.searchEmail;
    }

    let skip = ( page - 1 ) * itemsPerPage
    let take = itemsPerPage

    this.loadingDataGrid = true;
    //トータル取得
    memberService.countMembers(filter).then((totalNum) => {
      this.totalMembers = totalNum;
      //検索結果取得
      memberService.searchMembers(filter, skip, take).then((items) => {
        this.items = items;
        this.loadingDataGrid = false;
      });
    });
  }
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    
    this.searchMembers();
  }
  /**
   * 顧客編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(memberService.defaultMember);
  }

  /**
   * 顧客編集ダイアログを表示します.
   */
  public openEditDialog(item: Member): void {
    this.editDialog.open(item);
  }

  /**
   * 顧客編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Member): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("顧客を更新しました！");
  }

  /**
   * 顧客編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Member): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("顧客を登録しました！");
  }

  /**
   * 顧客削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Member): void {
    this.deleteDialog.open(item);
  }

  /**
   * 顧客削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Member): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("顧客を削除しました！");
  }

  /**
   * 顧客クーポン配布ダイアログを表示します.
   */
  public openDeliveryCouponDialog(item: Member): void {
    this.deliveryCouponDialog.open(item);
  }


  /**
   * 顧客クーポン配布ダイアログを表示します.
   */
  public onDeliveryCouponSuccess(): void {
    this.deliveryCouponDialog.close();
    NotificationState.notifySuccess("クーポンを配布しました！");
  }
}
